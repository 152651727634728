<template>
    <div class="home">
        <!-- 主体内容 -->
        <div class="topBz">
            <div class="son">
                <img v-if="step == 1" src="@/assets/sjrzbgA.png" alt="">
                <img v-else src="@/assets/sjrzbg.png" alt="">
                <div class="text" :style="{ color: step == 1 ? '#fff' : '' }">1.实名认证</div>
            </div>
            <div class="son">
                <img v-if="step == 2" src="@/assets/sjrzbgA.png" alt="">
                <img v-else src="@/assets/sjrzbg.png" alt="">
                <div class="text" :style="{ color: step == 2 ? '#fff' : '' }">2.选择店铺</div>
            </div>
            <div class="son">
                <img v-if="step == 3" src="@/assets/sjrzbgA.png" alt="">
                <img v-else src="@/assets/sjrzbg.png" alt="">
                <div class="text" :style="{ color: step == 3 ? '#fff' : '' }">3.完善信息</div>
            </div>
            <div class="son">
                <img v-if="step == 4" src="@/assets/sjrzbgA.png" alt="">
                <img v-else src="@/assets/sjrzbg.png" alt="">
                <div class="text" :style="{ color: step == 4 ? '#fff' : '' }">4.审核结果</div>
            </div>
        </div>

        <!-- 第一步 -->
        <div class="stepYi" v-if="step == 1">
            <div class="title">实名信息</div>
            <div class="form">
                <div class="formItem">
                    <div class="tit"><span>*</span>您的姓名</div>
                    <el-input v-model="stepObj.name" placeholder="请输入您的姓名"></el-input>
                </div>
                <div class="formItem">
                    <div class="tit"><span>*</span>您的身份证号</div>
                    <el-input v-model="stepObj.cardID" placeholder="请输入您的身份证号"></el-input>
                </div>
                <div class="formItem" style="width: 100%;">
                    <div class="tit"><span>*</span>上传身份证图片</div>
                    <div class="text">为保证您个人权益及接单后提现正常，提现账号必须与身份证名一 致，不允许使用他人身份证</div>
                    <div class="upload">
                        <div class="son">
                            <div class="title">头像面</div>
                            <div class="upla">
                                <el-upload class="avatar-uploader" action="https://api.cnc-x.cn//mall/uploads"
                                    :data="{ type: '7' }" :show-file-list="false" :on-success="handleAvatarSuccess">
                                    <img v-if="stepObj.sfzTx" :src="stepObj.sfzTx" class="avatar"
                                        style="width: 330px;object-fit: contain;">
                                    <img v-else src="@/assets/sfzA.png" style="width: 330px;object-fit: contain;" alt="">
                                </el-upload>
                            </div>
                        </div>
                        <div class="son">
                            <div class="title">国徽面</div>
                            <div class="upla">
                                <el-upload class="avatar-uploader" action="https://api.cnc-x.cn//mall/uploads"
                                    :data="{ type: '8' }" :show-file-list="false" :on-success="handleAvatarSuccess2">
                                    <img v-if="stepObj.sfzGh" :src="stepObj.sfzGh" class="avatar"
                                        style="width: 330px;object-fit: contain;">
                                    <img v-else src="@/assets/sfz.png" style="width: 330px;object-fit: contain;" alt="">
                                </el-upload>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 第二步 -->
        <div class="stepEr" v-if="step == 2">
            <div class="title">选择店铺类型</div>
            <div class="dpType">
                <div class="son cur" @click="dpTypeClick('普通店')"
                    :style="{ border: stepObj.dpType == '普通店' ? '1px solid #e1241b' : '' }">
                    <img src="@/assets/ptdA.png" v-if="stepObj.dpType == '普通店'" alt="">
                    <img src="@/assets/ptd.png" v-else alt="">
                    <div class="nameDp" :style="{ color: stepObj.dpType == '普通店' ? '#e1241b' : '' }">普通店</div>
                    <div class="text" :style="{ color: stepObj.dpType == '普通店' ? '#e1241b' : '' }">适合个人类型店铺</div>
                </div>
                <div class="son cur" @click="dpTypeClick('专卖店')"
                    :style="{ border: stepObj.dpType == '专卖店' ? '1px solid #e1241b' : '' }">
                    <img src="@/assets/zmdA.png" v-if="stepObj.dpType == '专卖店'" alt="">
                    <img src="@/assets/zmd.png" v-else alt="">
                    <div class="nameDp" :style="{ color: stepObj.dpType == '专卖店' ? '#e1241b' : '' }">专卖店</div>
                    <div class="text" :style="{ color: stepObj.dpType == '专卖店' ? '#e1241b' : '' }">适合拥有品牌授权证书的商户</div>
                </div>
                <div class="son cur" @click="dpTypeClick('旗舰店')"
                    :style="{ border: stepObj.dpType == '旗舰店' ? '1px solid #e1241b' : '' }">
                    <img src="@/assets/qjdA.png" v-if="stepObj.dpType == '旗舰店'" alt="">
                    <img src="@/assets/qjd.png" v-else alt="">
                    <div class="nameDp" :style="{ color: stepObj.dpType == '旗舰店' ? '#e1241b' : '' }">旗舰店</div>
                    <div class="text" :style="{ color: stepObj.dpType == '旗舰店' ? '#e1241b' : '' }">适合品牌方直接入驻</div>
                </div>

            </div>
        </div>

        <!-- 第三步 -->
        <div class="stepSan" v-if="step == 3">
            <div class="title">店铺信息</div>
            <div class="form">
                <div class="formItem" style="width: 100%;">
                    <div class="tit"><span>*</span>店铺logo</div>
                    <div class="upad">
                        <el-upload class="avatar-uploader" action="https://api.cnc-x.cn//mall/uploads" :data="{ type: '1' }"
                            :show-file-list="false" :on-success="handleAvatarSuccess3">
                            <img v-if="stepObj.dpLogo" style="width: 300px;object-fit: cover;" :src="stepObj.dpLogo"
                                class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>
                </div>
                <div class="formItem">
                    <div class="tit"><span>*</span>店铺名称</div>
                    <el-input v-model="stepObj.dpName" placeholder="请输入您的店铺名称"></el-input>
                </div>
                <div class="formItem">
                    <div class="tit"><span>*</span>店铺类型</div>
                    <el-input v-model="stepObj.dpType" disabled></el-input>
                </div>
                <div class="formItem">
                    <div class="tit"><span>*</span>主营业务</div>
                    <div @click="outerVisibleYW = true">
                        <el-input v-model="stepObj.dpBusiness" placeholder="请选择主营业务" disabled
                            suffix-icon="el-icon-arrow-down"></el-input>
                    </div>
                </div>
                <div class="formItem" v-if="stepObj.dpType !== '普通店'">
                    <div class="tit"><span>*</span>包含品牌</div>
                    <div @click="outerVisiblePP = true">
                        <el-input v-model="stepObj.dpBrand" placeholder="请选择品牌" disabled></el-input>
                    </div>
                </div>
                <div class="formItem">
                    <div class="tit"><span>*</span>注册地址</div>
                    <!-- <el-input v-model="stepObj.dpRegisteredAdd"></el-input> -->
                    <el-cascader :options="options" :props="{ value: 'label', label: 'label' }"
                        v-model="stepObj.dpRegisteredAdd" style="width: 100%;"></el-cascader>
                </div>
                <div class="formItem">
                    <div class="tit"><span>*</span>详细地址</div>
                    <el-input v-model="stepObj.dpDetailAdd"></el-input>
                </div>
                <div class="formItem">
                    <div class="tit"><span>*</span>企业资质</div>
                    <div class="text">请上传真实企业营业执照，一经上传，无法修改。</div>
                    <div class="upad">
                        <el-upload class="avatar-uploader" action="https://api.cnc-x.cn//mall/uploads" :data="{ type: '2' }"
                            :show-file-list="false" :on-success="handleAvatarSuccess4">
                            <img v-if="stepObj.dpQualifications" style="width: 300px;object-fit: cover;"
                                :src="stepObj.dpQualifications" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>
                </div>
                <div class="formItem">
                    <div class="tit"><span>*</span>授权证书</div>
                    <div class="text">授权证书是你与店铺相关品牌的品牌商签订的授权合同或者相关证明，请上传相关证明的清晰的拍照件。</div>
                    <div class="upad">
                        <el-upload class="avatar-uploader" action="https://api.cnc-x.cn//mall/uploads" :data="{ type: '2' }"
                            :show-file-list="false" :on-success="handleAvatarSuccess5">
                            <img v-if="stepObj.dpCertificate" style="width: 300px;object-fit: cover;"
                                :src="stepObj.dpCertificate" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>
                </div>
            </div>
        </div>

        <!-- 第四步 -->
        <div class="stepSi" v-if="step == 4">
            <div v-if="storeStatus == 'DSH'">
                <img src="@/assets/shenhe.png" alt="">
                <div class="text">您的店铺信息正在加急审核中...</div>
                <div class="btnCon" style="margin-top: 100px;">
                    <div class="btnSon cur" @click="goHome">回到首页</div>
                    <!-- <div class="btnSon2 cur" @click="contactService">联系客服</div> -->
                </div>
            </div>
            <div v-else-if="storeStatus == 'YJJ'">
                <img src="@/assets/shsb.png" alt="">
                <div class="text">您的店铺信息审核未通过~</div>
                <div class="text">请重新完善资料</div>
                <div class="text">拒绝原因：<span style="color: #303030;">{{ listContent }}</span> </div>
                <div class="btnCon" style="margin-top: 100px;">
                    <div class="btnSon cur" @click="goModify">前往修改</div>
                    <!-- <div class="btnSon2 cur" @click="contactService">联系客服</div> -->
                </div>
            </div>
            <div v-else>
                <img src="@/assets/shtg.png" alt="">
                <div class="text">您的店铺信息审核已通过</div>
                <div class="btnCon" style="margin-top: 100px;">
                    <div class="btnSon cur" @click="goHome">回到首页</div>
                    <!-- <div class="btnSon2 cur" @click="contactService">联系客服</div> -->
                </div>
            </div>
        </div>

        <!-- 主营业务对话框 -->
        <el-dialog title="主营业务" width="85%" :visible.sync="outerVisibleYW">
            <div class="dialogYW">
                <div class="l">
                    <div class="son cur" @click="typeNameCLick(index)" v-for="(i, index) in outerVisibleYWList" :key="index"
                        :class="[i.isShow ? 'sonAct' : '']">{{ i.typeName }}</div>
                </div>
                <div class="r" v-if="outerVisibleYWList.length > 0">
                    <div class="topImg">
                        <img :src="outerVisibleYWList.find(obj => obj.isShow).typeImage" alt="">
                    </div>
                    <div class="content" v-for="(it, ind) in outerVisibleYWList.find(obj => obj.isShow).children"
                        :key="ind">
                        <div class="title">{{ it.typeName }}</div>
                        <div class="sonBox">
                            <div class="sonn" v-for="(mt, inde) in it.children" :key="inde" @click="mtClick(mt)">
                                <div class="imgBox">
                                    <img class="img" :src="mt.typeImage" alt="">
                                    <img v-if="mt.isShow" class="imgXz" src="@/assets/xz.png" alt="">
                                </div>
                                <div class="name">{{ mt.typeName }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div slot="footer" class="dialog-footer">
                <el-button @click="outerVisibleYW = false">取 消</el-button>
                <el-button @click="resettingClick">重 置</el-button>
                <el-button type="primary" @click="determineYWClick">确 定</el-button>
            </div>
        </el-dialog>

        <!-- 选择品牌对话框 -->
        <el-dialog title="选择品牌" width="85%" :visible.sync="outerVisiblePP">
            <div class="dialogPP">
                <div class="son cur" v-for="(i, index) in outerVisiblePPList" :key="index" @click="outerVisibleBrand(i)">
                    <img :src="i.logo" alt="">
                    <div class="name">{{ i.brandName }}</div>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="outerVisiblePP = false">取 消</el-button>
                <!-- <el-button @click="resettingClick">重 置</el-button>
                <el-button type="primary" @click="determineYWClick">确 定</el-button> -->
            </div>
        </el-dialog>

        <!-- 按钮 -->
        <div class="btnCon">
            <div class="btnSon cur" v-if="step !== 4 && step !== 1" @click="Back">上一步</div>
            <div class="btnSon2 cur" v-if="step !== 4" @click="nextStep"> {{ step == 3 ? '提交' : '下一步' }}</div>
        </div>

    </div>
</template>

<script>
import { getMainBusiness, getMainSelectBrand, getMerchantSettlementSubmit, getStoreInfoQuery } from "@/utils/api/myApi/index"

export default {
    name: 'merchantSettlement',
    data() {
        return {
            // 步骤
            step: 1,
            // 主营业务对话框
            outerVisibleYW: false,
            // 主营业务列表
            outerVisibleYWList: [],
            // 品牌对话框
            outerVisiblePP: false,
            // 品牌列表
            outerVisiblePPList: [],
            // 地址数据
            options: [],
            // 入驻状态
            storeStatus: '',
            // 拒绝原因
            listContent: '',

            stepObj: {
                name: '', // 姓名
                cardID: '', // 身份证
                sfzGh: '', // 身份证 国徽面
                sfzTx: '', // 身份证 头像面
                dpType: '', // 店铺类型
                dpLogo: '', // 店铺logo
                dpName: '', // 店铺名称
                dpBusiness: '', // 主营业务
                dpBrand: '', // 店铺品牌
                dpRegisteredAdd: '', // 注册地址
                dpDetailAdd: '', // 详细地址
                dpQualifications: '', // 企业资质
                dpCertificate: '', // 授权证书
            }
        };
    },
    mounted() {
        // 那本地之前填写的数据
        if (localStorage.getItem('stepObj')) {
            this.stepObj = JSON.parse(localStorage.getItem('stepObj'))
        }
        this.options = this.$addresses
        // 获取主营业务数据
        this.outerVisibleYWClick()
        // 获取品牌数据
        this.outerVisiblePPClick()
        // 查询入驻店铺信息
        this.getStoreInfoCLick()
    },
    watch: {
        stepObj: {
            deep: true,
            handler(newVal) {
                // 在这里将新的属性值存储到本地
                localStorage.setItem('stepObj', JSON.stringify(newVal));
            }
        }
    },
    methods: {
        // 上传身份证
        handleAvatarSuccess(res, file) {
            // this.stepObj.sfzTx = URL.createObjectURL(file.raw); 
            if (res.statusCode == 8201) {
                this.stepObj.sfzTx = res.data;
                this.$message.success('上传成功');
            } else {
                this.$message.error('上传失败');
            }
        },
        handleAvatarSuccess2(res, file) {
            if (res.statusCode == 8201) {
                this.stepObj.sfzGh = res.data;
                this.$message.success('上传成功');
            } else {
                this.$message.error('上传失败');
            }
        },
        handleAvatarSuccess3(res, file) {
            if (res.statusCode == 8201) {
                this.stepObj.dpLogo = res.data;
                this.$message.success('上传成功');
            } else {
                this.$message.error('上传失败');
            }
        },
        handleAvatarSuccess4(res, file) {
            if (res.statusCode == 8201) {
                this.stepObj.dpQualifications = res.data;
                this.$message.success('上传成功');
            } else {
                this.$message.error('上传失败');
            }
        },
        handleAvatarSuccess5(res, file) {
            if (res.statusCode == 8201) {
                this.stepObj.dpCertificate = res.data;
                this.$message.success('上传成功');
            } else {
                this.$message.error('上传失败');
            }
        },
        // 查询入驻店铺信息
        async getStoreInfoCLick() {
            const res = await getStoreInfoQuery()
            console.log(res, 'res店铺信息')
            if (res.data) {
                this.storeStatus = res.data.storeStatus
                this.listContent = res.data.auditRemark;
                this.step = 4
            }
        },
        // 下一步
        nextStep() {
            if (this.step >= 4) return
            if (this.step == 1) {
                if (!this.stepObj.name) return this.$message.warning('请输入您的姓名');
                if (!this.stepObj.cardID) return this.$message.warning('请输入您的身份证号');
                const pattern = /^[1-9]\d{5}(?:19|20)\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1-2]\d|3[0-1])\d{3}[0-9Xx]$/;
                if (!pattern.test(this.stepObj.cardID)) return this.$message.warning('请输入正确的身份证号');
                if (!this.stepObj.sfzTx) return this.$message.warning('请上传头像面证件照');
                if (!this.stepObj.sfzGh) return this.$message.warning('请上传国徽面证件照');
            }
            if (this.step == 2) {
                if (!this.stepObj.dpType) return this.$message.warning('请选择店铺类型');
            }
            // 提 交
            if (this.step == 3) {
                if (!this.stepObj.dpLogo) return this.$message.warning('请上传店铺logo');
                if (!this.stepObj.dpName) return this.$message.warning('请输入店铺名称');
                if (!this.stepObj.dpBusiness) return this.$message.warning('请选择主营业务');
                if (!this.stepObj.dpBrand) return this.$message.warning('请选择店铺品牌');
                if (!this.stepObj.dpRegisteredAdd) return this.$message.warning('请选择注册地址');
                if (!this.stepObj.dpDetailAdd) return this.$message.warning('请选择详细地址');
                if (!this.stepObj.dpQualifications) return this.$message.warning('请上传企业资质');
                if (!this.stepObj.dpCertificate) return this.$message.warning('请上传授权证书');

                console.log(this.stepObj.dpRegisteredAdd)
                this.submitBtnClisk()
                return
            }
            this.step++
        },
        // 上一步
        Back() {
            if (this.step <= 1) return
            this.step--
        },
        // 回到首页
        goHome() {
            this.$router.push('/?actNum=0')
        },
        // 联系客服
        contactService() {
            alert('联系客服')
        },
        // 前往修改
        goModify() {
            this.step = 1
        },
        // 选择店铺类型
        dpTypeClick(index) {
            this.stepObj.dpType = index
        },
        // 获取主营业务数据
        async outerVisibleYWClick() {
            const res = await getMainBusiness()
            this.outerVisibleYWList = res.data
            this.outerVisibleYWList.forEach(obj => {
                // this.$set(obj, 'Selected', false);
                obj.isShow = false
            });
            this.outerVisibleYWList.map(f => f.children.map(h => h.children.map(d => {
                d.isShow = false
            })))
            this.outerVisibleYWList[0].isShow = true
        },
        // 左侧菜单选择事件
        typeNameCLick(index) {
            this.outerVisibleYWList.forEach(obj => {
                obj.isShow = false
            });
            this.outerVisibleYWList[index].isShow = true
        },
        // 选择业务事件
        mtClick(mt) {
            this.outerVisibleYWList.map(f => f.children.map(h => h.children.map(d => {
                if (d.id == mt.id) {
                    d.isShow = !d.isShow
                    return
                }
            })))
        },
        // 重置业务选择
        resettingClick() {
            this.outerVisibleYWList.map(f => f.children.map(h => h.children.map(d => {
                d.isShow = false
            })))
            this.$message.success('已重置');
        },
        // 业务选择确定
        determineYWClick() {
            let xzArr = []
            this.outerVisibleYWList.map(f => f.children.map(h => h.children.map(d => {
                if (d.isShow) {
                    xzArr.push(d)
                }
            })))
            console.log(xzArr, '选中的业务')
            this.stepObj.dpBusiness = xzArr.map(obj => obj.typeName).join(',');
            this.outerVisibleYW = false
        },
        // 获取主营业务数据
        async outerVisiblePPClick() {
            const res = await getMainSelectBrand({ start: '1', length: '80' })
            this.outerVisiblePPList = res.data.records
        },
        // 选择品牌
        outerVisibleBrand(val) {
            this.stepObj.dpBrand = val.brandName
            this.outerVisiblePP = false
        },
        // 提交
        async submitBtnClisk() {
            let formSubmite = {}
            formSubmite.address = this.stepObj.dpDetailAdd
            formSubmite.brand = this.stepObj.dpBrand
            formSubmite.city = this.stepObj.dpRegisteredAdd[1]
            formSubmite.county = this.stepObj.dpRegisteredAdd[2]
            formSubmite.province = this.stepObj.dpRegisteredAdd[0]
            formSubmite.pcc = `${this.stepObj.dpRegisteredAdd[0]}-${this.stepObj.dpRegisteredAdd[1]}-${this.stepObj.dpRegisteredAdd[2]}`
            formSubmite.idFrontImage = this.stepObj.sfzTx
            formSubmite.idReverseSideImage = this.stepObj.sfzGh
            formSubmite.idNumber = this.stepObj.cardID
            formSubmite.qualificationsUrl = `${this.stepObj.dpQualifications},${this.stepObj.dpCertificate}`
            formSubmite.realName = this.stepObj.name
            formSubmite.shouquanPic = this.stepObj.dpCertificate
            formSubmite.storeLog = this.stepObj.dpLogo
            formSubmite.storeMajor = this.stepObj.dpBusiness
            formSubmite.storeName = this.stepObj.dpName
            formSubmite.storeType = this.stepObj.dpType == '旗舰店' ? 'QJD' : this.stepObj.dpType == '专卖店' ? 'ZMD' : 'ZYD'
            formSubmite.storeTypeTxt = this.stepObj.dpType

            console.log(formSubmite, '商家入驻参数')
            const res = await getMerchantSettlementSubmit(formSubmite)
            console.log(res, '商家入驻')
            if (res.statusCode == 8201) {
                this.$message.success('提交成功');
                this.step = 4
            }
        },
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-input.is-disabled .el-input__inner {
    cursor: pointer !important;
}

/deep/ .el-input.is-disabled .el-input__icon {
    cursor: pointer !important;
}

/deep/ .el-dialog {
    background-color: #f4f4f4;
    margin-top: 50px !important;
}

/deep/ .el-dialog__body {
    padding: 10px 5px !important;
}

.upad {
    margin-left: 20px;
}

.avatar-uploader-icon:hover {
    border-color: #409EFF;
    color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
    transition: all .3s;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.home {
    width: 1200px;
    height: auto;
    margin: 10px auto;
    padding: 20px;
    box-sizing: content-box;
    background-color: #fff;
    border-radius: 10px;

    >.topBz {
        width: 100%;
        margin-bottom: 70px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        >.son {
            width: 225px;
            height: 50px;
            position: relative;

            >.text {
                font-size: 15px;
                color: #333;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            >img {
                width: 100%;
                object-fit: cover;
            }
        }
    }

    >.stepYi {
        width: 100%;

        >.title {
            font-size: 20px;
            color: #333;
            margin-bottom: 30px;
        }

        >.form {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            >.formItem {
                width: 580px;
                font-size: 14px;
                margin-bottom: 30px;

                >.tit {
                    margin-bottom: 10px;

                    >span {
                        color: #f00;
                        margin-right: 3px;
                    }
                }

                >.text {
                    color: #666;
                    font-size: 12px;
                    margin: 10px auto;
                    padding-left: 10px;
                }

                >.upload {
                    width: 100%;
                    display: flex;
                    justify-content: space-around;

                    >.son {
                        width: 560px;
                        height: 230px;
                        padding: 20px;
                        box-sizing: border-box;
                        border-radius: 10px;
                        background: #f4f6f8;
                        border: 1px solid #e5e7eb;
                        display: flex;

                        >.title {
                            color: rgb(119, 119, 119);
                            font-size: 14px;
                            margin-right: 20px;
                        }
                    }
                }
            }
        }

    }

    >.stepEr {
        width: 100%;

        >.title {
            font-size: 20px;
            color: #333;
            margin-bottom: 30px;
        }

        >.dpType {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-top: 50px;
            margin-bottom: 50px;

            >.son {
                width: 330px;
                height: 160px;
                border-radius: 12px;
                border: 1px solid #c8cbcf;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                >img {
                    width: 54px;
                    object-fit: contain;
                }

                >.nameDp {
                    font-size: 12px;
                    margin-top: 3px;
                    margin-bottom: 5px;
                }

                >.text {
                    font-size: 12px;
                    color: #787878;
                }
            }
        }
    }

    >.stepSan {
        width: 100%;

        >.title {
            font-size: 20px;
            color: #333;
            margin-bottom: 30px;
        }

        >.form {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            >.formItem {
                width: 580px;
                font-size: 14px;
                margin-bottom: 30px;

                >.tit {
                    margin-bottom: 20px;

                    >span {
                        color: #f00;
                        margin-right: 3px;
                    }
                }

                >.text {
                    color: #666;
                    font-size: 12px;
                    margin: 10px auto;
                    padding-left: 10px;
                }
            }
        }
    }

    >.stepSi {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        >div {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: 100px;

            >img {
                width: 128px;

            }

            >.text {
                width: 100%;
                text-align: center;
                font-size: 16px;
                font-weight: bold;
                margin-top: 20px;
            }
        }
    }

    .btnCon {
        width: 100%;
        display: flex;
        padding-left: 20px;
        margin-top: 40px;
        box-sizing: border-box;

        >.btnSon {
            width: 120px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid rgb(200, 203, 207);
            margin-right: 16px;
        }

        >.btnSon2 {
            width: 120px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid rgb(200, 203, 207);
            background: rgb(225, 36, 27);
            color: rgb(255, 255, 255);
        }
    }
}

.dialogYW {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: space-between;

    >.l {
        width: 200px;
        height: 100%;
        overflow-y: auto;

        >.son {
            font-size: 14px;
            color: #555;
            width: 100%;
            text-align: center;
            height: 50px;
            line-height: 50px;
        }

        >.sonAct {
            color: #e1241b;
            font-weight: bold;
        }
    }

    >.r {
        width: calc(100% - 220px);
        height: 100%;
        padding: 0px 10px;
        box-sizing: border-box;
        overflow-y: auto;

        >.topImg {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;

            >img {
                height: 100px;
                object-fit: contain;
            }
        }

        >.content {
            width: 100%;
            height: auto;
            padding: 10px;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 10px;
            margin-bottom: 20px;

            >.title {
                font-weight: bold;
            }

            >.sonBox {
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                // justify-content: space-around;

                >.sonn {
                    width: 30%;
                    display: flex;
                    margin-bottom: 15px;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-right: 3%;

                    >.imgBox {
                        width: 60px;
                        height: 60px;
                        position: relative;

                        >.img {
                            height: 54px;
                            width: 54px;
                            object-fit: cover;
                        }

                        >.imgXz {
                            width: 24px;
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            object-fit: contain;
                        }
                    }


                    >.name {
                        width: 120px;
                        text-align: center;
                        color: #333;
                    }
                }
            }
        }
    }
}

.dialogPP {
    width: 100%;
    height: 70vh;
    display: flex;
    overflow-y: auto;
    padding: 0 40px;
    box-sizing: border-box;
    flex-wrap: wrap;

    >.son {
        width: 230px;
        height: 65px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 20px;
        background-color: #fff;
        padding: 20px;
        box-sizing: border-box;

        >img {
            width: 58px;
            height: 58px;
            object-fit: contain;
        }

        >.name {
            color: #999;
            font-size: 18px;
            margin-left: 10px;
        }
    }
}

/* 整个滚动条 */
::-webkit-scrollbar {
    /* 对应纵向滚动条的宽度 */
    width: 5px;
    /* 对应横向滚动条的宽度 */
    height: 5px;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
    background-color: #b3b6bb8f;
    border-radius: 32px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
    // background-color: #d3d3d386;
    background-color: #f4f4f4;
    border-radius: 32px;
}
</style>